
import colors from "../../../../assets/theme/base/colors";

const { text } = colors;

const formLabel = {
  styleOverrides: {
    root: {
      color: "#000000",
    },
  },
};

export default formLabel;
