
import colors from "../../../../assets/theme/base/colors";
import borders from "../../../../assets/theme/base/borders";
import boxShadows from "../../../../assets/theme/base/boxShadows";


import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { grey, white } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

const tabs = {
  styleOverrides: {
    root: {
      position: "relative",
      
      borderRadius: borderRadius.xl,

      padding: pxToRem(4),
      overflow : 'auto'
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.veriskBlue.main,
    },
  
 
   
  },
};

export default tabs;
