

import { Skeleton } from "@mui/material";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import PropTypes from "prop-types";
import NavBarStyles from "./styles/NavBarStyles";
import { useSelector } from "react-redux";
import { getIsClaimSelected,getSelectedClaim, loadingClaimStatus } from "../../core/reducers/claims-reducer";
 
import { SUCCEEDED } from "../../core/models/constants/index";
import { useMaterialUIController } from "../../context";


export const TitleSection = ({brandName}) => {
    const {paddingXaxis, paddingBottom, paddingTop, textAlign, paddingBottomClaimView, paddingTopClaimView} = NavBarStyles?.navBarTitleAllignment;
    const {titlePaddingLeft,textColor,titleMarginLeft,fontSize,variantType, componentType, fontSizeClaimDashBoardView, fontWeightClaimTotal, fontSizeClaimTotal} = NavBarStyles?.titletextStyles;
    const {displayStyle,position,marginTop,marginRight,padding,cursorType} = NavBarStyles?.tabStyles;
    const {display, alignItems, textDecoration} = NavBarStyles?.tabLinkStyles;
    const {skeletonStyles} = NavBarStyles;
    const [controller,] = useMaterialUIController();
    const {layout} = controller;
    const claim = useSelector(getSelectedClaim);
    const ClaimDashboardView = useSelector(getIsClaimSelected);
    const pageStatus = useSelector(loadingClaimStatus);
    const isClaimDashboardViewAndNotSuperUserView = ClaimDashboardView && layout!=='superUser'
    return (<MDBox pt={ClaimDashboardView ? paddingTopClaimView : paddingTop} pb={ClaimDashboardView ? paddingBottomClaimView : paddingBottom}  px={paddingXaxis} textAlign={textAlign}>
        <MDBox
          display={displayStyle}
          position= {position}
          top={marginTop}
          right={marginRight}
          p={padding}
          sx={{ cursor: cursorType }}
        >
        </MDBox>
        <MDBox  display={display} alignItems={alignItems} style={{ textDecoration: textDecoration }}  >
          <MDBox
            width={!brandName && "100%"}
          >
            <MDTypography component={componentType} variant={variantType}  paddingLeft= {titlePaddingLeft} marginLeft={titleMarginLeft} fontSize = {isClaimDashboardViewAndNotSuperUserView ?fontSizeClaimDashBoardView : fontSize} color={textColor}>
              {isClaimDashboardViewAndNotSuperUserView ? (pageStatus === SUCCEEDED ? 
                                                              (claim?.policyholder?.customerName) : 
                                                              (<Skeleton   sx={skeletonStyles} />) ) 
                                  : brandName}
            </MDTypography>
        {ClaimDashboardView && pageStatus === SUCCEEDED && layout!=='superUser' &&
            <>
       
              <MDTypography component={componentType} variant={variantType} fontWeight = {fontWeightClaimTotal}  paddingLeft= {titlePaddingLeft} marginLeft={titleMarginLeft} marginTop={1} fontSize = {fontSizeClaimTotal} color={textColor}>
                Claim Total
              </MDTypography>
              <MDTypography component={componentType} variant={variantType}   paddingLeft= {titlePaddingLeft} marginLeft={titleMarginLeft}  fontSize = {fontSizeClaimDashBoardView} color={textColor}>
             { `£${claim?.estimate?.toLocaleString()}`}
              </MDTypography>
             
            </>
            } 
          </MDBox>
        </MDBox>
      </MDBox>)
}


TitleSection.propTypes = {
    brandName: PropTypes.string.isRequired
  };
  
