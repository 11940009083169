var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { handleSetProjectId } from "../reducers/teamMembers-reducer";
import { apiClient } from "./configure-services";
export var getTeamMembersAsync = function (claimNumber, dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, response, error_1;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 2, , 3]);
                requestUrl = "teammembers/" + claimNumber;
                return [4 /*yield*/, apiClient.get(requestUrl)];
            case 1:
                response = _c.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                    throw new Error('Failed to fetch team members');
                }
                dispatch(handleSetProjectId((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data.projectId));
                return [2 /*return*/, (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data];
            case 2:
                error_1 = _c.sent();
                throw new Error(error_1.message || 'Failed to fetch team members');
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getaAailableResourcesAsync = function (resourceType, dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, response, error_2;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                requestUrl = "teammembers/available-resources/" + resourceType;
                return [4 /*yield*/, apiClient.get(requestUrl)];
            case 1:
                response = _b.sent();
                return [2 /*return*/, (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data];
            case 2:
                error_2 = _b.sent();
                return [2 /*return*/, error_2.message];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var removeTeamMemberAsync = function (resourceID, claimNumber, senderUsername) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, body, headers, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "teammembers/" + claimNumber;
                body = {
                    "senderUsername": senderUsername,
                    "usePrimaryContactAsSender": true,
                    "teamMember": resourceID.toString()
                };
                headers = {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                };
                return [4 /*yield*/, apiClient.delete(requestUrl, {
                        data: body,
                        headers: headers
                    })];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                    throw new Error('Failed to remove member');
                }
                response.data.data.resourceID = resourceID;
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_3 = _a.sent();
                throw new Error(error_3.message || 'Failed to remove member');
            case 3: return [2 /*return*/];
        }
    });
}); };
export var addTeamMembersAsync = function (claimNumber, teamMembers, senderUsername) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, body, headers, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "teammembers/".concat(claimNumber);
                body = {
                    senderUsername: senderUsername,
                    usePrimaryContactAsSender: true,
                    teamMembers: teamMembers
                };
                headers = {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                };
                return [4 /*yield*/, apiClient.post(requestUrl, body, {
                        headers: headers
                    })];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 201) {
                    throw new Error('Failed to add team members');
                }
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_4 = _a.sent();
                throw new Error('Failed to add team members');
            case 3: return [2 /*return*/];
        }
    });
}); };
export var addExternalResourceAsync = function (claimNumber, teamMembers, senderUsername) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, body, headers, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "teammembers/".concat(claimNumber, "/external");
                body = {
                    senderUsername: senderUsername,
                    usePrimaryContactAsSender: true,
                    teamMembers: teamMembers
                };
                headers = {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                };
                return [4 /*yield*/, apiClient.post(requestUrl, body, {
                        headers: headers
                    })];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 201) {
                    throw new Error('Failed to add team members');
                }
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_5 = _a.sent();
                throw new Error('Failed to add team members');
            case 3: return [2 /*return*/];
        }
    });
}); };
