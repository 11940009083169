import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox"
import { useMaterialUIController } from "../../../context";
import { handleDebounce } from "../ClaimUtils";


const General = () =>
{
    const [controller,dispatch] = useMaterialUIController();
    const { claimRequestBody } = controller

    
    const handleCustomerNameChange = (event) =>
    {
        handleDebounce("customerName", event.target.value,dispatch);
    }

    const handleAddressChange =(event) =>
    {
        if(event.target.id === 'address-line-1')
            handleDebounce("street", event.target.value,dispatch);
        else
        handleDebounce("address2", event.target.value,dispatch);
    }

    const handleCityChange =  (event) => 
    {
        handleDebounce("city", event.target.value,dispatch)    
    }
    
    
    const handleCountyChange = (event) =>
    {
        handleDebounce("county", event.target.value,dispatch);
    }

    const handlePostCodeChange = (event) =>
    {
        handleDebounce("postal", event.target.value,dispatch);

    }

    return( 
   
        <MDBox mx={2} >
            <FormControl fullWidth variant="standard">
            <TextField
                  fullWidth
                  value={claimRequestBody?.customerName || ''} 
                  margin="normal"
                  id="customer-name"
                  label="Customer Name"
                  variant="standard"
                  required
                  onChange = {handleCustomerNameChange}
                  inputProps={{ minLength: 3, maxLength: 100 }}
                />
              
              <TextField
                  fullWidth
                  margin="normal"
                  value={claimRequestBody?.street || ''}
                  id="address-line-1"
                  label="Address Line 1"
                  required
                  variant="standard"
                  onChange = {handleAddressChange}
                  inputProps={{ minLength: 3, maxLength: 100 }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  value={claimRequestBody?.address2 || ''}
                  id="address-line-2"
                  label="Address Line 2"
                  variant="standard"
                  onChange = {handleAddressChange}
                  inputProps={{ minLength: 3, maxLength: 100 }}
                />  

                <TextField
                  fullWidth
                  margin="normal"
                  id="city"
                  value={claimRequestBody?.city || ''}
                  label="City/Town"
                  required
                  variant="standard"
                  onChange = {handleCityChange}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                />
                
                <TextField
                  fullWidth
                  margin="normal"
                  id="county"
                  value={claimRequestBody?.county || ''}
                  label="County"
                  required
                  variant="standard"
                  onChange = {handleCountyChange}
                  inputProps={{ minLength: 3, maxLength: 100 }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  id="postcode"
                  label="Postcode"
                  required
                  value={claimRequestBody?.postal || ''}
                  variant="standard"
                  onChange = {handlePostCodeChange}
                  inputProps={{ minLength: 3, maxLength: 100 }}
                />
                
            </FormControl>
        </MDBox>
   )
}

export default General;


