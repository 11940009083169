import { Card, IconButton, TextField } from "@mui/material"
import MDBox from "../../../components/MDBox"
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from "../../../components/MDTypography";
import { cardTitleStyles } from "./styles/styles";
import MDButton from "../../../components/MDButton";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { useEffect, useState } from "react";
import { handleDebounce } from "../ClaimUtils";
import { setCreateClaimRequestBody, useMaterialUIController } from "../../../context";
import { uploadCardStyles } from "../../../assets/common-styles/styles";

const PolicyInfo = () => {
    const [controller,dispatch] = useMaterialUIController();
    const { claimRequestBody } = controller;
   
      useEffect(() => {
    
        if(!claimRequestBody?.coverages)
        {
            const newCoverage = [{ id: 1, coverageName: '', sumInsured: '', excess: '' }];
            setCreateClaimRequestBody(dispatch,'coverages', newCoverage);
        }
    }, []);
      
    
    const handleCoverageChange = (id, field, value) => {

        if ((field === 'sumInsured' || field === 'excess') && value !=='') {

            const moneyRegex = /^\d+(\.\d{0,2})?$/;
    
      
            if (!moneyRegex.test(value) || parseFloat(value) < 0) {

                return; 
            }
        }
        const updatedCoverages = claimRequestBody.coverages.map(coverage =>
            coverage.id === id ? { ...coverage, [field]: value } : coverage
        );
        handleDebounce('coverages', updatedCoverages, dispatch);
    };

    const handleAddCoverage = () => {
        const maxId = claimRequestBody.coverages.reduce((max, coverage) => Math.max(max, coverage.id), 0);

        const newCoverage = { id: maxId+1, coverageName: '', sumInsured: '', excess: '' };
        const updatedCoverages = [...claimRequestBody.coverages, newCoverage];
        handleDebounce('coverages', updatedCoverages,dispatch);
    };
  
    const handleRemoveCoverage= (idToRemove) => {
        if(idToRemove !== 1)
        {
        const updatedCoverages = claimRequestBody.coverages.filter(coverage => coverage.id !== idToRemove);
        handleDebounce('coverages', updatedCoverages,dispatch);
        }
      }; 
    const handlePolicyNumberChange = (event) => 
    {
        handleDebounce('policyNumber', event?.target?.value ,dispatch);
    }

    return (
        <MDBox mt={2} mx={0} mb={2}>
                <TextField
                           
                            margin="normal"
                            value={claimRequestBody?.policyNumber}
                            id="Policy Number"
                            required
                            label="Policy Number"
                            variant="standard"
                            onChange={(e) => handlePolicyNumberChange(e)}
                            inputProps={{ minLength: 2, maxLength: 100 }}
                            />
                {claimRequestBody?.coverages?.map((coverage, index) => (
                    <Card variant="outlined"  sx={uploadCardStyles} key={index}>
                       
                       <MDBox sx={{display:'flex',py:1,px:2}}>
                            <MDTypography sx={cardTitleStyles}>
                                Add Coverage
                            </MDTypography>
                            <IconButton
                                aria-label="close"
                                sx={{ position: 'absolute', top: 4, right: 4 }}
                                onClick={()=>{handleRemoveCoverage(coverage?.id)}}
                            >
                                <CloseIcon color='veriskBlack'/>
                            </IconButton>
                        </MDBox>
                        <MDBox sx={{mx:2,mb:2, display:'flex', flexDirection:'column', gap:2}} >
                        <TextField
                            fullWidth
                            margin="normal"
                            value={coverage?.coverageName}
                            id="covrage-name"
                            label="Coverage Name"
                            variant="standard"
                            onChange={(e) => handleCoverageChange(coverage.id, 'coverageName', e.target.value)}
                            inputProps={{ minLength: 2, maxLength: 100 }}
                            />
                        
                        <TextField
                            fullWidth
                            margin="normal"
                            value={coverage?.sumInsured}
                            id="sum-insured"
                            label="Sum Insured"
                            placeholder="£000"
                            variant="standard"
                            inputProps={{ minLength: 3, maxLength: 100 }}
                            onChange={(e) => handleCoverageChange(coverage.id, 'sumInsured', e.target.value)}

                            />

                        <TextField
                            fullWidth
                            margin="normal"
                            value={coverage?.excess}
                            id="address-line-2"
                            placeholder="£000"
                            label="Excess"
                            variant="standard"
                            onChange={(e) => handleCoverageChange(coverage.id, 'excess', e.target.value)}
                            inputProps={{ minLength: 3, maxLength: 100 }}
                            />  
                        </MDBox>
                    </Card>
                ))}
                <MDBox sx={{mt:2}}>
                    <MDButton fullWidth 
                    onClick={handleAddCoverage}
                    sx={{
                        border: '1px solid lightgray',
                        borderRadius: '4px', 
                        }}> 
                        <MDTypography color='veriskBlue' fontSize={pxToRem(15)}>
                            Add Coverage 
                        </MDTypography>
                    </MDButton>
                </MDBox>
            </MDBox>
    )
}

export default PolicyInfo