var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { assignmentCreateAssignment, assignmentGetAssignments } from "../actions/assignments-actions";
import { DEFAULT_PAGE, FAILED, IDLE, LOADING, SUCCEEDED } from "../models/constants/index";
var initialState = {
    loadingAssignments: IDLE,
    filteringCompleted: false,
    loadingCreateAssignment: IDLE,
    assignments: [],
    filteredAssignments: [],
    selectedAssignment: undefined,
    pageNumber: DEFAULT_PAGE,
    totalAssignments: 0
};
export var assignmentsSlice = createSlice({
    name: "assignments",
    initialState: initialState,
    reducers: {
        handleSelectedAssignment: function (state, _a) {
            var payload = _a.payload;
            state.selectedAssignment = payload;
        },
        handleFilteredAssignment: function (state, _a) {
            var payload = _a.payload;
            state.filteredAssignments = payload;
            state.filteringCompleted = true;
        },
        startFiltering: function (state) {
            state.filteringCompleted = false;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(assignmentGetAssignments.pending, function (state) {
            state.loadingAssignments = LOADING;
        });
        builder.addCase(assignmentGetAssignments.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.assignments = payload.data;
            state.totalAssignments = payload.total;
            state.loadingAssignments = SUCCEEDED;
        });
        builder.addCase(assignmentGetAssignments.rejected, function (state) {
            state.loadingAssignments = FAILED;
        });
        builder.addCase(assignmentCreateAssignment.pending, function (state) {
            state.loadingCreateAssignment = LOADING;
        });
        builder.addCase(assignmentCreateAssignment.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.assignments = __spreadArray([payload.data], state.assignments, true);
            state.loadingCreateAssignment = SUCCEEDED;
        });
        builder.addCase(assignmentCreateAssignment.rejected, function (state) {
            state.loadingCreateAssignment = FAILED;
        });
    },
});
export var handleSelectedAssignment = (_a = assignmentsSlice.actions, _a.handleSelectedAssignment), handleFilteredAssignment = _a.handleFilteredAssignment, startFiltering = _a.startFiltering;
export default assignmentsSlice.reducer;
export var getAssignments = function (state) { return state === null || state === void 0 ? void 0 : state.assignmentState.assignments; };
export var getAssignment = function (state, assignmentId) {
    return state.assignmentState.assignments.find(function (assignment) { return (assignment === null || assignment === void 0 ? void 0 : assignment.assignmentId) == assignmentId; });
};
export var loadingAssignmentsStatus = function (state) { return state === null || state === void 0 ? void 0 : state.assignmentState.loadingAssignments; };
export var filteringStatus = function (state) { return state === null || state === void 0 ? void 0 : state.assignmentState.filteringCompleted; };
export var getAssignmentsCount = function (state) { return state === null || state === void 0 ? void 0 : state.assignmentState.totalAssignments; };
export var getFilteredAssignments = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.assignmentState) === null || _a === void 0 ? void 0 : _a.filteredAssignments; };
