import { createSlice } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../models/constants/index";
import { claimGetCustomTasks } from "../actions/customTasks-actions";
var initialState = {
    loadingCustomTasks: IDLE,
    tasks: [],
    totalTasks: 0
};
export var customTasksSlice = createSlice({
    name: "customTasks",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(claimGetCustomTasks === null || claimGetCustomTasks === void 0 ? void 0 : claimGetCustomTasks.pending, function (state) {
            state.loadingCustomTasks = LOADING;
        });
        builder.addCase(claimGetCustomTasks === null || claimGetCustomTasks === void 0 ? void 0 : claimGetCustomTasks.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.tasks = payload === null || payload === void 0 ? void 0 : payload.data;
            state.totalTasks = payload === null || payload === void 0 ? void 0 : payload.total;
            state.loadingCustomTasks = SUCCEEDED;
        });
        builder.addCase(claimGetCustomTasks === null || claimGetCustomTasks === void 0 ? void 0 : claimGetCustomTasks.rejected, function (state) {
            state.loadingCustomTasks = FAILED;
        });
    }
});
export default customTasksSlice.reducer;
export var getCustomTasks = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.customTasksState) === null || _a === void 0 ? void 0 : _a.tasks; };
