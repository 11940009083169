import NavBarStyles from "./NavBarStyles";
import pxToRem from "../../../assets/theme/functions/pxToRem";

function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, functions } = theme;
  const { active, transparentSidenav, whiteSidenav, darkMode } = ownerState;
  const { white, transparent, grey } = palette;
  const { pxToRem, rgba } = functions;
  const tabsBgColor = NavBarStyles?.base?.tabsBgColor

  return {
    background: active
      ? tabsBgColor
      : transparent.main,
 
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: `${pxToRem(8)} ${pxToRem(14)}`,
    margin: `${pxToRem(0)} ${pxToRem(0)}`,
    borderRadius: 'none',
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    opacity:1,
    [breakpoints.up("xl")]: {
      transition: transitions.create(["box-shadow", "background-color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },

   
  };
}

function collapseIconBox(theme, ownerState) {
  const { palette, transitions, functions } = theme;
  const { transparentSidenav, whiteSidenav, darkMode, active } = ownerState;
  const { white, dark } = palette;
  const { pxToRem } = functions;

  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(30),
    color:
      (transparentSidenav && !darkMode && !active) || (whiteSidenav && !active)
        ? dark.main
        : "#f0f2f5",
    borderRadius: "0px",
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    "& svg, svg g": {
      color: transparentSidenav || whiteSidenav ? dark.main : white.main,
      borderRadius: "0px"
    },
  };
}


const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
  color: "#f0f2f5",
  
});


function collapseText(theme, ownerState) {
  const {  transitions, breakpoints, functions } = theme;
  const { miniSidenav, transparentSidenav, active } = ownerState;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(10),

    [breakpoints.up("xl")]: {
      opacity:  1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(20),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      marginLeft : "10px",
      pt : '10px',
      fontWeight : "large"
      
    },
  };
}


export { collapseItem, collapseIconBox, collapseIcon,collapseText };
