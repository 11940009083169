
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import MDTypography from "../MDTypography";
import NavBarStyles from "./styles/NavBarStyles";
// Material Dashboard 2 React components
import MDBox from "../MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText
} from "./styles/sidenavCollapse"


// Material Dashboard 2 React context
import { useMaterialUIController } from "../../context";

function SidenavCollapse({ icon, name, active, ...rest }) {
  const [controller] = useMaterialUIController();

  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  const textColor = NavBarStyles?.titletextStyles?.textColor;

  const activeTabStyle = NavBarStyles?.activeTabStyle;
  const {marginLeft, paddingLeft, fontWeight, fontSize, marginBottom} = NavBarStyles?.tabTextStyles
  return (
    <ListItem component="li">
      
      {active &&  <div style={activeTabStyle} />}
      
      
      <MDBox
        {...rest}
      
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
          
        }
      > 
        <ListItemIcon
         sx={(theme) =>
          collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
        }
        >
            <Icon sx={(theme) => collapseIcon(theme, { active })}  fontSize="medium" color ="info" >{icon}</Icon>
        </ListItemIcon>
         <MDTypography sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          } 
          component = 'h2' variant="caption" fontWeight = {fontWeight}  paddingLeft= {paddingLeft} marginLeft={marginLeft} fontSize = {fontSize} color={textColor}  >
              {name}
            </MDTypography>
      </MDBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
