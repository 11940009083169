import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import PropTypes from "prop-types"
import dayjs from 'dayjs';

const CustomDateField = ({dateLabel, handleDateChange,selectedDate}) => {

    return( <LocalizationProvider dateAdapter={AdapterDayjs}>
    
        <DatePicker
            sx={{maxWidth: pxToRem(135) }}
            label = {dateLabel}
            onChange={handleDateChange}
            disableFuture = {true}
            
            value = {dayjs(selectedDate)}
            labelId={dateLabel+"select-label"}
            views={['year', 'month', 'day']}
            slotProps={{
                        textField: {
                        variant : "standard",
                    
                        fullWidth:false,
                        },   
                }}   
        />
        
    </LocalizationProvider>)

}


export default CustomDateField

CustomDateField.propTypes = {
    dateLabel : PropTypes.string,
    handleDateChange: PropTypes.func,
    selectedDate : PropTypes.object
};

CustomDateField.defaultProps = {
    dateLabel : '',
    handleDateChange : ()=>{}
    

};
