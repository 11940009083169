
import { forwardRef } from "react";


import PropTypes from "prop-types";


import MDTypographyRoot from "../../components/MDTypography/MDTypographyRoot";


import { useMaterialUIController } from "../../context";

const MDTypography = forwardRef(
  (
    { color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest },
    ref
  ) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MDTypographyRoot
        {...rest}
        ref={ref}
        ownerState={{
          color,
          textTransform,
          verticalAlign,
          fontWeight,
          opacity,
          textGradient,
          darkMode,
        }}
      >
        {children}
      </MDTypographyRoot>
    );
  }
);


MDTypography.defaultProps = {
  color: "dark",
  fontWeight: false,
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
};


MDTypography.propTypes = {
  color: PropTypes.string,
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold",500,'large']),
  textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase", "Capitalize"]),
  verticalAlign: PropTypes.oneOf([
    "unset",
    "baseline",
    "sub",
    "super",
    "text-top",
    "text-bottom",
    "middle",
    "top",
    "bottom",
  ]),
  textGradient: PropTypes.bool,
  children: PropTypes.node,
  opacity: PropTypes.number,
};

export default MDTypography;
