export var DEFAULT_PAGE_SIZE = 12;
export var DEFAULT_PAGE_DISPLAY_SIZE = 4;
export var DEFAULT_PAGE = 1;
export var DEFAULT_PAGE_ACTION = true;
export var BASE_URL = process.env.REACT_APP_BASE_URL && process.env.REACT_APP_BASE_URL !== ''
    ? process.env.REACT_APP_BASE_URL : 'https://propertyserverqa.verisk.co.uk/api/';
// export const BASE_URL: string = 'https://localhost:7001/api/';
export var CLAIM_NOTE_URL = process.env.CLAIM_NOTE_URL && process.env.CLAIM_NOTE_URL !== ''
    ? process.env.CLAIM_NOTE_URL : 'https://cak3yxv4kk6cg5wpopttdva6kq0ogxou.lambda-url.eu-west-1.on.aws';
// PAGE STATUSES
export var IDLE = 'IDLE';
export var LOADING = 'LOADING';
export var SUCCEEDED = 'SUCCEEDED';
export var FAILED = 'FAILED';
