
import colors from "../../../../assets/theme/base/colors";


import pxToRem from "../../../../assets/theme/functions/pxToRem";
import boxShadow from "../../../../assets/theme/functions/boxShadow";

const { white } = colors;

const stepIcon = {
  
};

export default stepIcon;
