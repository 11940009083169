import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import rootReducer from "../reducers";
var logger = createLogger();
// const middlewares = [logger, thunk];
// const middlewares = [logger];
var middlewares = [];
var initialState = {};
var devTools = process.env.NODE_ENV === "production"
    ? applyMiddleware.apply(void 0, middlewares) : composeWithDevTools(applyMiddleware.apply(void 0, middlewares));
var store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === "production" ? false : true,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware().concat(middlewares);
    }
});
export default store;
