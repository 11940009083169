var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiClient } from "./configure-services";
import { EndPoints } from "../models/constants/endpoints";
export var getClaimsAsync = function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "".concat(EndPoints.claims, "?pageNumber=").concat(request.pageNumber, "&pageSize=").concat(request.pageSize, "&paginate=").concat(request.paginate, "&searchTerm=").concat(request.searchTerm);
                return [4 /*yield*/, apiClient.get(requestUrl)];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                    throw new Error('Failed to fetch  Claims');
                }
                return [2 /*return*/, response.data];
            case 2:
                error_1 = _a.sent();
                if ((error_1 === null || error_1 === void 0 ? void 0 : error_1.message.includes("Failed to fetch")) || (error_1 === null || error_1 === void 0 ? void 0 : error_1.message.includes("NetworkError")) || (error_1 === null || error_1 === void 0 ? void 0 : error_1.message.includes("ERR_CONNECTION_REFUSED"))) {
                    throw new Error('Network error: Unable to connect to the server. Please try again later.');
                }
                else {
                    throw new Error('Failed to fetch Claims.');
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getClaimNotesAsync = function (request, claimId) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, params, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "notes/" + claimId;
                params = request;
                return [4 /*yield*/, apiClient.get(requestUrl, { params: params })];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                    throw new Error('Failed to fetch  Notes');
                }
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_2 = _a.sent();
                throw new Error('Failed to fetch  Notes');
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getClaimAsync = function (claimId) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                requestUrl = "".concat(EndPoints.claims, "/").concat(claimId);
                return [4 /*yield*/, apiClient.get(requestUrl)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
        }
    });
}); };
export var createClaimAsync = function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "".concat(EndPoints.claims);
                return [4 /*yield*/, apiClient.post(requestUrl, request)];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 201) {
                    throw new Error('Failed to create claim');
                }
                return [2 /*return*/, response.data];
            case 2:
                error_3 = _a.sent();
                throw new Error('Failed to create claim');
            case 3: return [2 /*return*/];
        }
    });
}); };
export var addNoteAsync = function (assignment, note) { return __awaiter(void 0, void 0, void 0, function () {
    var currentDateTime, requestUrl, body, headers, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                currentDateTime = new Date().toISOString();
                requestUrl = "notes/".concat(assignment);
                body = {
                    note: note,
                    dateTime: currentDateTime
                };
                headers = {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                };
                return [4 /*yield*/, apiClient.post(requestUrl, body, {
                        headers: headers
                    })];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 201) {
                    throw new Error('Failed to add note');
                }
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_4 = _a.sent();
                throw new Error('Failed to add note');
            case 3: return [2 /*return*/];
        }
    });
}); };
