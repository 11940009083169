import axios from "axios";
import { BASE_URL } from "../models/constants";
import { httpStatusCodes } from "../models/constants/http-status-codes";
export var apiClient = axios.create({
    baseURL: BASE_URL,
});
var ACCESS_TOKEN;
var REFRESH_TOKEN;
export function configureAxios(_a) {
    var accessToken = _a.accessToken, refreshToken = _a.refreshToken, showErrorMessage = _a.showErrorMessage, navigate = _a.navigate;
    ACCESS_TOKEN = accessToken || localStorage.getItem("okta-token-storage") || ""; // need to change this to okta-token-storage
    REFRESH_TOKEN = refreshToken || "";
    apiClient.interceptors.request.use(function (req) {
        if (ACCESS_TOKEN) {
            req.headers.authorization = "Bearer ".concat(ACCESS_TOKEN);
        }
        return req;
    }, function (error) {
        return Promise.reject(error);
    });
    apiClient.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        var currentPagePath = window.location.pathname;
        if (currentPagePath !== "/login" && error.response.status === httpStatusCodes.unauthorized) {
            redirectToLogin(navigate);
        }
        else if (error.response) {
            showErrorMessage("Something went wrong!");
        }
        else if (error.request) {
            showErrorMessage("Network error, please try again");
        }
        else {
            showErrorMessage("An error occured, please try again");
        }
        return Promise.reject(error);
    });
}
function redirectToLogin(navigate) {
    localStorage.clear();
    navigate('/login');
}
