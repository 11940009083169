import { FormControl, InputLabel, Select, TextField } from "@mui/material"
import MDBox from "../../../../components/MDBox"
import MDTypography from "../../../../components/MDTypography"
import { countryCodeLabelStyles, StyledMenuItem } from "../../collaboration/styles/PhFormStyles"
import { countryCodes } from "../../collaboration/constants"
import PropTypes from 'prop-types';

export const PhoneTextField = ({textFieldLabel,phoneError,handlePhoneNumberChange,handleCountryCodeChange,countryCode,phoneNumberValue}) =>
{
    return   (
    <MDBox sx={{ display: 'flex', gap: 2, alignItems: phoneError ? 'center' : 'flex-end',  mt: 2 }}>
        <FormControl variant="standard" sx={{ flex: 3,minWidth: 40  }}>
            <InputLabel id="country-code-label"   >
                <MDTypography sx = {countryCodeLabelStyles}>
                    Country Code
                </MDTypography> 
            </InputLabel>
            <Select
                labelId="country-code-label"
                value={countryCode}
                onChange={handleCountryCodeChange}
                required
                renderValue={(selected) => selected}    
            >
        
                {countryCodes.map((item) => {
                    const numericCode = item.code.replace(/\D/g, '');
                return (
                <StyledMenuItem key={item} value={numericCode}>
                    {item.code} {item.country} 
                </StyledMenuItem>
                );
            })}
            </Select>
        </FormControl>
        <TextField
            error = {phoneError}
            helperText = {phoneError && "Please enter a valid Phone Number"}
            label={textFieldLabel}
            variant="standard"
            onChange={handlePhoneNumberChange}   
            type="tel"
            value = {phoneNumberValue}
            inputProps={{ minLength: 7, maxLength: 15 }}
            sx={{ flex: 7,mr:4}}
        />
    </MDBox>
)
}

PhoneTextField.propTypes = {
    textFieldLabel: PropTypes.string,       
    phoneError: PropTypes.bool,        
    handlePhoneNumberChange: PropTypes.func.isRequired,                   
    handleCountryCodeChange: PropTypes.func.isRequired, 
    countryCode: PropTypes.string ,
    phoneNumberValue: PropTypes.string            
};

PhoneTextField.defaultProps = {
    textFieldLabel: '',   
    countryCode : "44",
    phoneNumberValue : '',
    phoneError : false

};
