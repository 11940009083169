import General from "./claim-sections/General";
import { debounce } from "../../examples/LayoutContainers/DashboardLayout";
import { setCreateClaimRequestBody } from "../../context";
import ContactInfo from "./claim-sections/ContactInfo";
import CreateAssignment from "./claim-sections/CreateAssignment";
import ClaimInfo from "./claim-sections/ClaimInfo";
import PolicyInfo from "./claim-sections/PolicyInfo";

export const handleDebounce = debounce((fieldName,fieldValue,dispatch,setRequestBody=setCreateClaimRequestBody) => {
 
    setRequestBody(dispatch, fieldName, fieldValue);        
    }, 10)
   
export const tabLabels = [
    'General', 'Contact Info', 'Claim Info', 'Assignment', 'Policy Info'
  ];

export const tabContent = [
    <General key={0} />,
    <ContactInfo key={1} />,
    <ClaimInfo key={2} />,
    <CreateAssignment key={3}/>,
    <PolicyInfo key={4} />,
  ];


  export const claimRequiredFields = ["customerName","street","city","postal","dataSetId",'peril','cause','claimNumber','lossDescription','email','policyNumber']
  export const phoneFields = ["Home Phone", "Mobile Phone", "Business Phone"];
  export const assignmentRequiredFields = ["jobType","jobSize","assignee"]

  export const validateRequiredFields = (claimRequestBody,assignmentRequestBody) => {
    const claimRequiredFieldsValid = claimRequiredFields.every(
      (field) =>
        claimRequestBody[field] !== undefined &&
      claimRequestBody[field] !== ''
    );

    const assignmentRequiredFieldsValid = assignmentRequiredFields.every(
      (field) =>
        assignmentRequestBody[field] !== undefined &&
      assignmentRequestBody[field] !== ''
    );
  
    const atLeastOnePhoneValid = phoneFields.some(
      (phoneField) => claimRequestBody[phoneField]?.number
    );
   
    const allPhoneErrorsFalse = phoneFields.every(
      (phoneField) => !claimRequestBody[phoneField]?.phoneError
    );

    const isEmailInValid = claimRequestBody?.email?.emailError
    const isClaimInfoValid = claimRequestBody?.errorClaimInfo
  
    return claimRequiredFieldsValid && atLeastOnePhoneValid && allPhoneErrorsFalse && assignmentRequiredFieldsValid && !isClaimInfoValid && !isEmailInValid;
  }; 

 