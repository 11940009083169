import MDBox from "../../../components/MDBox"
import DropDownField from "./components/DropDrownField"
import { PerilTypes, Causes } from "../../../core/models/claims/constants"
import { setCreateClaimRequestBody, useMaterialUIController } from "../../../context";
import { handleDebounce } from "../ClaimUtils";
import { TextField } from "@mui/material";


import pxToRem from "../../../assets/theme/functions/pxToRem";
import CustomDateField from "./components/CustomDateField";
import MDTypography from "../../../components/MDTypography";

const ClaimInfo = () => {

    const [controller,dispatch] = useMaterialUIController();
    const {claimRequestBody} = controller;
    
  
    const handleDateValidation = (selectedDate) =>
    {   

        const isValid = selectedDate.toString().toLowerCase() !== "invalid date"
        const isFutureDate = selectedDate > new Date();
        if(isFutureDate || !isValid)
        {
            setCreateClaimRequestBody(dispatch,'errorClaimInfo',true )    
        }
        else
        {
            setCreateClaimRequestBody(dispatch,'errorClaimInfo',false )    
        }
    }

    const handlePerilChange = (event) =>
    {
        setCreateClaimRequestBody(dispatch,'peril',event.target.value )
    }

    const handleCauseChange = (event) =>
    {
        setCreateClaimRequestBody(dispatch,'cause',event.target.value )           
    }
    const handleLossDateChange = (event) =>
    {    
        const selectedDate = event?.toDate() 
        handleDateValidation(selectedDate);
        setCreateClaimRequestBody(dispatch,'lossDate',selectedDate )           
    }

    const handleReportedDateChange = (event) =>
        
    {    
        const selectedDate = event?.toDate()
        handleDateValidation(selectedDate);
        setCreateClaimRequestBody(dispatch,'receiptDate',selectedDate  )        
    }

    const handleClaimReferenceChange = (event) =>
    { 
        handleDebounce('claimNumber',event?.target?.value  ,dispatch)       
    }
    const handleLossDescChange = (event) =>
    { 
        handleDebounce('lossDescription',event?.target?.value  ,dispatch) 
                    
    }

    
    return(
        <MDBox sx={{mx:2}}>
             {claimRequestBody?.errorClaimInfo === true &&<MDTypography  variant="gradient" color={"veriskRed"}  sx={{ display: 'block',fontFamily : 'Roboto' }}>
               Please enter a valid date
            </MDTypography>}
            <DropDownField dropDownLabel={"Peril*"} dropDownList={PerilTypes} selectedValue={claimRequestBody?.peril} handleValueChange={(e)=>handlePerilChange(e)} />
            <DropDownField dropDownLabel={"Cause*"} dropDownList={Causes} selectedValue={claimRequestBody?.cause} handleValueChange={(e)=>handleCauseChange(e)} />
            <MDBox display="flex" gap={3} alignItems="center" sx={{mx:2}}>
                <CustomDateField  dateLabel={"Loss Date*"} handleDateChange={(e)=>handleLossDateChange(e)} selectedDate={claimRequestBody?.lossDate}/>
                <CustomDateField  dateLabel={"Reported Date*"} handleDateChange={(e)=>handleReportedDateChange(e)} selectedDate={claimRequestBody?.receiptDate}/>
           </MDBox>
           <MDBox sx={{mx:2}}>
           <TextField
                  fullWidth
                  margin="normal"
                  value={claimRequestBody?.claimNumber}
                  id="claimReference"
                  label="Claim Reference"
                  required
                  variant="standard"
                  onChange = {handleClaimReferenceChange}
                  inputProps={{ minLength: 3, maxLength: 100 }}
                />
            </MDBox>
            <MDBox mx={2} mt={4}>
                <MDTypography 
                sx ={{
                        fontWeight : "regular",
                        fontSize: pxToRem(12),
                        color : "#707070",
                    }}>
                 Description of Loss and/or instructions*
                </MDTypography>
                <TextField
                    fullWidth
                    multiline
                    rows={12}
                    variant="outlined"

                    value={claimRequestBody?.lossDescription}
                    onChange={handleLossDescChange}
                    inputProps={{ maxLength: 4000 }}
                    helperText={`${claimRequestBody?.lossDescription?.length || 0}/4000 characters`}
                />
            </MDBox>
        </MDBox>
        )
}

export default ClaimInfo