import { FAILED, IDLE, LOADING, SUCCEEDED } from "../models/constants/index";
import { createSlice } from "@reduxjs/toolkit";
import { authSuperUserStatus } from "../actions/auth-actions";
var initialState = {
    loadingAuth: IDLE,
    user: undefined,
    token: '',
    isAuthenticated: false,
    isSuperUser: null,
};
export var authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        handleAuth: function (state, _a) {
            var payload = _a.payload;
            state.user = payload;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(authSuperUserStatus.pending, function (state) {
            state.loadingAuth = LOADING;
        });
        builder.addCase(authSuperUserStatus.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isSuperUser = payload;
            state.loadingAuth = SUCCEEDED;
            state.isAuthenticated = true;
        });
        builder.addCase(authSuperUserStatus.rejected, function (state) {
            state.isSuperUser = false; // need to change this
            state.loadingAuth = FAILED;
            state.isAuthenticated = false;
        });
    },
});
export var handleAuth = authSlice.actions.handleAuth;
export default authSlice.reducer;
export var getUser = function (state) { return state.authState.user; };
export var getAuthStatus = function (state) { return state.authState.isAuthenticated; };
export var getSuperUserStatus = function (state) { return state.authState.isSuperUser; };
