import { createSlice } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../models/constants/index";
import { claimGetFlags, claimGetSelectedFlags } from "../actions/flags-actions";
var initialState = {
    loadingFlags: IDLE,
    loadingSelectedFlags: IDLE,
    flags: [],
    claimFlags: [],
    totalFlags: 0,
    totalSelectedFlags: 0
};
export var flagsSlice = createSlice({
    name: "flags",
    initialState: initialState,
    reducers: {
        handleRemoveClaimFlag: function (state, _a) {
            var payload = _a.payload;
            state.claimFlags = state.claimFlags.filter(function (flag) { return flag.toLowerCase() !== payload.toLowerCase(); });
        },
    },
    extraReducers: function (builder) {
        builder.addCase(claimGetFlags === null || claimGetFlags === void 0 ? void 0 : claimGetFlags.pending, function (state) {
            state.loadingFlags = LOADING;
        });
        builder.addCase(claimGetFlags === null || claimGetFlags === void 0 ? void 0 : claimGetFlags.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.flags = payload === null || payload === void 0 ? void 0 : payload.data;
            state.totalFlags = payload === null || payload === void 0 ? void 0 : payload.total;
            state.loadingFlags = SUCCEEDED;
        });
        builder.addCase(claimGetFlags === null || claimGetFlags === void 0 ? void 0 : claimGetFlags.rejected, function (state) {
            state.loadingFlags = FAILED;
        });
        builder.addCase(claimGetSelectedFlags === null || claimGetSelectedFlags === void 0 ? void 0 : claimGetSelectedFlags.pending, function (state) {
            state.loadingSelectedFlags = LOADING;
        });
        builder.addCase(claimGetSelectedFlags === null || claimGetSelectedFlags === void 0 ? void 0 : claimGetSelectedFlags.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.claimFlags = payload === null || payload === void 0 ? void 0 : payload.data;
            state.totalSelectedFlags = payload === null || payload === void 0 ? void 0 : payload.total;
            state.loadingSelectedFlags = SUCCEEDED;
        });
        builder.addCase(claimGetSelectedFlags === null || claimGetSelectedFlags === void 0 ? void 0 : claimGetSelectedFlags.rejected, function (state) {
            state.loadingSelectedFlags = FAILED;
        });
    }
});
export var handleRemoveClaimFlag = flagsSlice.actions.handleRemoveClaimFlag;
export default flagsSlice.reducer;
export var getFlags = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.flagsState) === null || _a === void 0 ? void 0 : _a.flags; };
export var getClaimFlags = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.flagsState) === null || _a === void 0 ? void 0 : _a.claimFlags; };
