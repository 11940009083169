import { createSlice } from "@reduxjs/toolkit";
import { accountGetAccounts } from "../../actions/super-user/accounts-actions";
import { DEFAULT_PAGE, FAILED, IDLE, LOADING, SUCCEEDED } from "../../models/constants";
var initialState = {
    loadingAccounts: IDLE,
    accounts: [],
    totalAccounts: 0,
    pageNumber: DEFAULT_PAGE
};
export var accountsSlice = createSlice({
    name: "accounts",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(accountGetAccounts === null || accountGetAccounts === void 0 ? void 0 : accountGetAccounts.pending, function (state) {
            state.loadingAccounts = LOADING;
        });
        builder.addCase(accountGetAccounts === null || accountGetAccounts === void 0 ? void 0 : accountGetAccounts.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.accounts = payload.data;
            state.totalAccounts = payload.total;
            state.loadingAccounts = SUCCEEDED;
        });
        builder.addCase(accountGetAccounts === null || accountGetAccounts === void 0 ? void 0 : accountGetAccounts.rejected, function (state) {
            state.loadingAccounts = FAILED;
        });
    },
});
export default accountsSlice.reducer;
export var getAccounts = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.accountsState) === null || _a === void 0 ? void 0 : _a.accounts; };
export var loadingAccountsStatus = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.accountsState) === null || _a === void 0 ? void 0 : _a.loadingAccounts; };
export var getAccountsCount = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.accountsState) === null || _a === void 0 ? void 0 : _a.totalAccounts; };
