
import { assigneeList, DataSets,JobTypes,JobSize } from "../../../core/models/assignments/constants";
import DropDownField from "./components/DropDrownField";
import { setCreateAssignmentRequestBody, setCreateClaimRequestBody, useMaterialUIController } from "../../../context";
import { handleDebounce } from "../ClaimUtils";

const CreateAssignment = () => {
    const [controller,dispatch] = useMaterialUIController();
    const {claimRequestBody,assignmentRequestBody} = controller;
 
   
    const handleDatasetChange = (event) =>
    {
  
      
        setCreateClaimRequestBody(dispatch,'dataSetId',event.target.value) ;  

    }

    const handleJobTypeChange = (event) =>
    {

        setCreateAssignmentRequestBody(dispatch,'jobType',event.target.value)
                
    }
    const handleJobSizeChange = (event) =>
    {
        setCreateAssignmentRequestBody(dispatch,'jobSize',event.target.value)
                
    }

    const handleAssigneeChange = (event) =>
    {

        setCreateAssignmentRequestBody(dispatch,'assignee',event.target.value)
    
    }
    return (
        <div>
            <DropDownField dropDownLabel ={"Data Set*"} dropDownList={DataSets} selectedValue={claimRequestBody?.dataSetId} handleValueChange={(e) => handleDatasetChange(e)} />
             {/*   do not remove +1. */}
            <DropDownField dropDownLabel ={"Job Type*"} dropDownList={JobTypes} selectedValue={assignmentRequestBody?.jobType} handleValueChange={(e) => handleJobTypeChange(e)} />
            <DropDownField dropDownLabel ={"Estimated Job Size*"} dropDownList={JobSize} selectedValue={assignmentRequestBody?.jobSize} handleValueChange={(e) => handleJobSizeChange(e)} />
            <DropDownField dropDownLabel ={"Assignee"} dropDownList={assigneeList} selectedValue={assignmentRequestBody?.assignee} handleValueChange={(e) => handleAssigneeChange(e)} />
        </div>
    )
}

export default CreateAssignment;