var _a;
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../models/constants/index";
import { createSlice } from "@reduxjs/toolkit";
import { claimGetTeamMembers, getResourcesApi } from "../actions/teamMembers-action";
var initialState = {
    loadingTeamMembers: IDLE,
    loadingRemoveTeamMembers: IDLE,
    loadingResources: IDLE,
    success: true,
    transactionId: "",
    projectId: 0,
    senderUsername: "",
    errorCode: "",
    errorDescription: "",
    teamMembers: [],
    resources: [],
    projectActionResults: [],
    totalTeamMembers: 0
};
export var teamMembersSlice = createSlice({
    name: "teamMembers",
    initialState: initialState,
    reducers: {
        handleSetProjectId: function (state, _a) {
            var payload = _a.payload;
            state.projectId = payload;
        },
        setLoadingTeamMembers: function (state, _a) {
            var payload = _a.payload;
            state.loadingTeamMembers = payload;
        }
    },
    extraReducers: function (builder) {
        builder.addCase(claimGetTeamMembers === null || claimGetTeamMembers === void 0 ? void 0 : claimGetTeamMembers.pending, function (state) {
            state.loadingTeamMembers = LOADING;
        });
        builder.addCase(claimGetTeamMembers === null || claimGetTeamMembers === void 0 ? void 0 : claimGetTeamMembers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.teamMembers = payload === null || payload === void 0 ? void 0 : payload.data;
            state.totalTeamMembers = payload === null || payload === void 0 ? void 0 : payload.total;
            state.loadingTeamMembers = SUCCEEDED;
        });
        builder.addCase(claimGetTeamMembers === null || claimGetTeamMembers === void 0 ? void 0 : claimGetTeamMembers.rejected, function (state) {
            state.loadingTeamMembers = FAILED;
        });
        /*
        We can use this in future if we want reduce unnecessary fetch calls
    
        builder
          .addCase(removeMember?.pending, (state) => {
            state.loadingRemoveTeamMembers = LOADING;
          })
          .addCase(removeMember?.fulfilled, (state, { payload }) => {
            state.loadingRemoveTeamMembers = SUCCEEDED;
            state.teamMembers = state.teamMembers.filter((member) => member?.resourceId?.toString() !== payload?.data?.resourceID);
          })
          .addCase(removeMember?.rejected, (state) => {
            state.loadingRemoveTeamMembers = FAILED;
        
          });
          */
        builder.addCase(getResourcesApi.pending, function (state) {
            state.loadingResources = LOADING;
        });
        builder.addCase(getResourcesApi.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.resources = payload.data;
            state.loadingResources = SUCCEEDED;
        });
        builder.addCase(getResourcesApi.rejected, function (state) {
            state.loadingResources = FAILED;
        });
    }
});
export var handleSetProjectId = (_a = teamMembersSlice.actions, _a.handleSetProjectId), setLoadingTeamMembers = _a.setLoadingTeamMembers;
export default teamMembersSlice.reducer;
export var getTeamMembers = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.teamMembersState) === null || _a === void 0 ? void 0 : _a.teamMembers; };
export var loadingTeamMembers = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.teamMembersState) === null || _a === void 0 ? void 0 : _a.loadingTeamMembers; };
export var loadingRemoveTeamMembers = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.teamMembersState) === null || _a === void 0 ? void 0 : _a.loadingRemoveTeamMembers; };
export var getprojectId = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.teamMembersState) === null || _a === void 0 ? void 0 : _a.projectId; };
export var getResources = function (state) { return state.teamMembersState.resources; }; // Selector for resources
export var loadingResources = function (state) { return state.teamMembersState.loadingResources; };
