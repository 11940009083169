
import { SecureRoute } from "./SecureRoute";
import CreateClaim from "./layouts/claims/CreateClaim";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import RocketOutlinedIcon from '@mui/icons-material/RocketOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DomainIcon from '@mui/icons-material/Domain';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// @mui icons
import React, { lazy, Suspense } from 'react';
import { Route } from "react-router-dom";

//import ClaimsCalendar from "./layouts/claims/calendar/claim-calendar";
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';


const Claims = lazy(() => import('./layouts/claims'));
const ClaimDashboard = lazy(() => import('./layouts/claims/dashboard/claim-dashboard'));
const ClaimStatus = lazy(() => import('./layouts/claims/status/claim-status'));
const ClaimNotes = lazy(() => import('./layouts/claims/notes/claim-notes'));
const ClaimMedia = lazy(() => import('./layouts/claims/media/claim-media'));
const SuperUser = lazy(() => import('./layouts/super-user'));
const Users = lazy(() => import('./layouts/super-user/users/Users'));
//const Login = lazy(() => import('./layouts/authentication/login'));
const ClaimCollaboration = lazy(() => import('./layouts/claims/collaboration/claim-collaboration'));

const LoadingFallback = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress color="veriskBlue" size={80}/>
  </div>
);

const LazyComponent = ({ component: Component }) => {
  return (
  <Suspense fallback={<LoadingFallback />}>
    <Component />
  </Suspense>
  )
};

const superUserRoutes = [
  {
    type: "collapse",
    name: "Accounts",
    key: "accounts  ",
    icon: <DomainIcon />,
    route: "/superuser/accounts",
    component: <LazyComponent component={SuperUser} />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <PeopleAltIcon />,
    route: "/superuser/users",
    component: <LazyComponent component={Users} />,
  }

]


const routes = [
  {
    type: "collapse",
    name: "Claims",
    key: "claims",
    icon: <WorkOutlineIcon />,
    route: "/claims",
    component: <LazyComponent component={Claims} />,
  }
];

const claimsRoutes = [
  {
    type: "collapse",
    name: "Claims Dashboard",
    key: "dashboard",
    icon: <RocketOutlinedIcon />,
    route: "/claims/:id/dashboard",
    component: <LazyComponent component={ClaimDashboard} />,
  },
  {
    type: "collapse",
    name: "Claim Status",
    key: "status",
    icon: <PublishedWithChangesOutlinedIcon />,
    route: "/claims/:id/status",
    component: <LazyComponent component={ClaimStatus} />,
  },
  {
    type: "collapse",
    name: "Notes",
    key: "notes",
    icon: <DescriptionOutlinedIcon />,
    route: "/claims/:id/notes",
    component: <LazyComponent component={ClaimNotes} />,
  },
  {
    type: "collapse",
    name: "Media",
    key: "media",
    icon: <PermMediaOutlinedIcon />,
    route: "/claims/:id/media",
    component: <LazyComponent component={ClaimMedia} />,
  },
  {
    type: "collapse",
    name: "Collaboration",
    key: "collaboration",
    icon: <PeopleAltOutlinedIcon />,
    route: "/claims/:id/collaboration",
    component: <LazyComponent component={ClaimCollaboration} />,
  },
];

export {routes,claimsRoutes,superUserRoutes};

/*
const getLoginRoute = () => {
  return {
    key: "login",
    route: "/login",
    component: <LazyComponent component={Login} />,
  }
};
*/

/*
export const getCreateAssignmentRoute = () => {
  return {
    key: "create-assignment",
    route: "/claims/:id/assignments/create",
    component: <CreateAssignment />,
  }
};
*/
const fetchRoutes = (allRoutes,isSuperUserRoute=false) => {
  let appRoutes = allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
   
      return (
        <Route path={route.route} element={<SecureRoute isSuperUserRoute={isSuperUserRoute}> {route.component} </SecureRoute>} key={route.key}>
          
         
        </Route>
      );
    }

    return null;
  });
  return appRoutes
}

export const getSuperUserRoutes = (allroutes) => {
  let appRoutes = fetchRoutes(allroutes,true);
  appRoutes = appRoutes.flat().filter(Boolean);
  return appRoutes
}

  
export const getRoutes = (allRoutes) => {
  
  let appRoutes = fetchRoutes(allRoutes);
  appRoutes = appRoutes.flat().filter(Boolean);

 


  return appRoutes;
};
LazyComponent.propTypes = {
  component: PropTypes.elementType.isRequired,
};
