

import { useEffect, useState } from "react";

import { drawerContainer } from "../../common-styles/styles";

import PropTypes from "prop-types";
import { setDisableActionDrawerButton, useMaterialUIController } from "../../../../context";
import MDBox from "../../../../components/MDBox";
import { Grid, Switch, TextField } from "@mui/material";
import { superUserValidateRequiredFields } from "../SuperUsersUtil";
import MDTypography from "../../../../components/MDTypography";
import { getStatusLabel } from "../../../../core/models/super-user/constants";
import pxToRem from "../../../../assets/theme/functions/pxToRem";




const AddSuperUser = ({isAddRequest,superUserRequest,setSuperUserRequest}) => {
    
    const [,dispatch] = useMaterialUIController();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[A-Za-z\s]*$/;
    const [originalStatus,setOriginalStatus] = useState();

   
    useEffect(() => {
        setOriginalStatus(superUserRequest?.userStatus)
        isAddRequest && setSuperUserRequest({});
    },[]);


    useEffect(() => {
        const areAllRequiredFieldsPresent = superUserValidateRequiredFields(['firstName', 'lastName','email'],['emailError'],superUserRequest);
      
        if ((!areAllRequiredFieldsPresent && isAddRequest===true) ||(isAddRequest===false && originalStatus===superUserRequest?.userStatus)) 
            setDisableActionDrawerButton(dispatch, true);
        else 
            setDisableActionDrawerButton(dispatch, false);
        

        
      }, [superUserRequest,originalStatus])
      
      const isValidName = (name) =>
      {
        return nameRegex.test(name);
      }

    const handleEmailChange = (event,key,errorKey) => {
            const email = event?.target?.value;
            const isValidEmail =emailRegex.test(email.trim()) && email.trim().toLowerCase().endsWith("@verisk.com");
            if(isAddRequest) // Need to change when we have the option to edit email and 
            {
                setSuperUserRequest((prevState) => ({
                    ...prevState, 
                    [key]: email, 
                    [errorKey] : !isValidEmail
                }));
        }
        }

    const handleNameChange = (event,key) => {
        const name = event?.target?.value
        const isValid = isValidName(name);
        if(isValid && isAddRequest){
            setSuperUserRequest((prevState) => ({
                ...prevState, 
               [key]:name.charAt(0).toUpperCase() + name.slice(1)
              }));
        }
      };
    
 
  
      const handleStatusChange = (event) => {
        const isChecked = event.target.checked; 
        const userStatus = isChecked ? 1 : 0; 
        setSuperUserRequest((prevState) => ({
            ...prevState, 
           ['userStatus']:userStatus
          }));
    }
    
   
    return ( 
       <MDBox sx={drawerContainer}>
            <MDBox sx={{ml:5,mr:5}}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="email"
                  label="Verisk email"
                  required
                  variant="standard"
                  value={superUserRequest?.email || ''}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                  onChange = {(e)=>handleEmailChange(e,'email','emailError')}
                  error = {superUserRequest?.emailError}
                  helperText = {superUserRequest?.emailError && "Please enter a valid verisk email"}
              
                />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                        fullWidth
                        margin="normal"
                        id="first-name"
                        label="First name*"
                        variant="standard"
                        value={superUserRequest?.firstName || ''}
                        inputProps={{ minLength: 2, maxLength: 100 }}
                        onChange = {(e)=>handleNameChange(e,'firstName')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                        fullWidth
                        margin="normal"
                        id="xid"
                        label="Last name"
                        required
                        value={superUserRequest?.lastName || ''}
                        variant="standard"
                        onChange = {(e)=>handleNameChange(e,'lastName')}
                        inputProps={{ minLength: 2, maxLength: 100 }}
                        />
                    </Grid>
                </Grid>
                {!isAddRequest &&   <MDBox sx={{display:'flex',gap:2, alignItems: 'center',mt:4}}>
                    <MDTypography color="veriskBlack" fontSize = {pxToRem(17)}>User {getStatusLabel(superUserRequest?.userStatus)}</MDTypography>
                    <Switch onChange={handleStatusChange} checked={superUserRequest?.userStatus === 1}/>
                    </MDBox>}
            </MDBox>       
       </MDBox>           
   )
}
export default AddSuperUser;

AddSuperUser.propTypes = {
    isAddRequest: PropTypes.bool,
    superUserRequest : PropTypes.object,
    setSuperUserRequest : PropTypes.func
  };


  AddSuperUser.defaultProps = {
    isAddRequest: true,
    superUserRequest:{},
    
  };