
import colors from "../../../assets/theme/base/colors";
import borders from "../../../assets/theme/base/borders";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../assets/theme/functions/pxToRem";

const { white } = colors;


const sidenav = {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {

    
      backgroundColor: white.main,
      height: `100%`,
      margin: 0,
      borderRadius: 0,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;
