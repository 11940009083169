var _a;
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../models/constants/index";
import { createSlice } from "@reduxjs/toolkit";
import { dashboardGetDashboard } from "../actions/dashboard-actions";
var initialState = {
    loadingDashboard: IDLE,
    dashboard: undefined,
};
export var dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(dashboardGetDashboard.pending, function (state) {
            state.loadingDashboard = LOADING;
        });
        builder.addCase(dashboardGetDashboard.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.dashboard = payload.data;
            state.loadingDashboard = SUCCEEDED;
        });
        builder.addCase(dashboardGetDashboard.rejected, function (state) {
            state.loadingDashboard = FAILED;
        });
    },
});
export var _b = _a = dashboardSlice.actions;
export default dashboardSlice.reducer;
export var getDashboard = function (state) { return state.dashboardState.dashboard; };
export var getPageStatus = function (state) { return state.dashboardState.loadingDashboard; };
