import { createSlice } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../models/constants/index";
import { mediaGetMedia } from "../actions/media-actions";
var initialState = {
    loadingMedia: IDLE,
    media: [],
    description: "",
    totalMedia: 0
};
export var mediaSlice = createSlice({
    name: "media",
    initialState: initialState,
    reducers: {
        handleSetMediaLoadingState: function (state) {
            state.loadingMedia = IDLE;
            state.media = [];
        }
    },
    extraReducers: function (builder) {
        builder.addCase(mediaGetMedia.pending, function (state) {
            state.loadingMedia = LOADING;
        });
        builder.addCase(mediaGetMedia.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.media = payload.data;
            state.totalMedia = payload.total;
            state.loadingMedia = SUCCEEDED;
        });
        builder.addCase(mediaGetMedia.rejected, function (state) {
            state.loadingMedia = FAILED;
        });
        /*
            builder.addCase(assignmentCreateAssignment.pending, (state) => {
              state.loadingCreateAssignment = LOADING;
            });
            builder.addCase(assignmentCreateAssignment.fulfilled, (state, { payload }) => {
              state.assignments = [payload.data, ...state.assignments]
              state.loadingCreateAssignment = SUCCEEDED;
            });
            builder.addCase(assignmentCreateAssignment.rejected, (state) => {
              state.loadingCreateAssignment = FAILED;
            });
            
            */
    },
});
export var handleSetMediaLoadingState = (mediaSlice === null || mediaSlice === void 0 ? void 0 : mediaSlice.actions).handleSetMediaLoadingState;
export default mediaSlice.reducer;
export var getMedia = function (state) { return state === null || state === void 0 ? void 0 : state.mediaState.media; };
