/* eslint-disable react/prop-types */
import { useState,useEffect } from "react";
import MDBox from "../../components/MDBox";


import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import TabList from '@mui/lab/TabList';
import  { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import pxToRem from "../../assets/theme/functions/pxToRem";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { tabLabels,tabContent, validateRequiredFields } from "./ClaimUtils";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import { setCreateClaimRequestBody, setDisableActionDrawerButton, useMaterialUIController } from "../../context";
import { drawerContainer } from "./common-styles/styles";

function CreateClaim() {
  const [value, setValue] = useState(0);
  const [controller,dispatch] = useMaterialUIController();
  const { claimRequestBody,assignmentRequestBody } = controller


  useEffect(() => {
    const defaultDate = new Date()
    setCreateClaimRequestBody(dispatch,'lossDate', defaultDate)  
    setCreateClaimRequestBody(dispatch,'receiptDate', defaultDate)  
}, []);


  useEffect(() => {
    const areAllRequiredFieldsPresent = validateRequiredFields(claimRequestBody,assignmentRequestBody);

    if (!areAllRequiredFieldsPresent) 
        setDisableActionDrawerButton(dispatch, true);
    else 
        setDisableActionDrawerButton(dispatch, false);
    
    
  }, [claimRequestBody,assignmentRequestBody])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const nextButtonHandler = () =>
  {
    setValue((prevValue) => prevValue + 1);
  }
  return (
    <DashboardLayout>
      <MDBox sx={drawerContainer}>
        <MDBox >
        <TabContext value={value} >
          <TabList  
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            selectionFollowsFocus
            allowScrollButtonsMobile
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '& svg': {
                  fontSize: `${pxToRem(40)} !important`, 
                },
                '&.Mui-disabled': { opacity: 0.3 },
              },
             
            }}
          >
            {tabLabels.map((label) => (
              <Tab key={label} label={label} />
            ))}
          </TabList>
          <TabPanel key={value} value={value} >
            {tabContent[value]}
          </TabPanel>
        </TabContext>
        </MDBox>
      {value !== (tabLabels.length -1) && 
      <MDBox sx={{display:"flex", justifyContent:"flex-end" ,mb:4,mr:2,}}>
        <MDButton 
          variant="gradient" 
          onClick={nextButtonHandler} 
          color="veriskWhite" 
          sx={{  
                padding : 1, 
                boxShadow : "6px 6px 6px #00000029",
                opacity:1,
             
              }}
        >
          <MDTypography 
              color={"veriskBlue"} 
              sx={{
                    fontSize : pxToRem(15), 
                    fontWeight:"medium"
                  }}
          >
            {"Next"}
          </MDTypography>
        </MDButton>
        </MDBox>
}     </MDBox>
    </DashboardLayout>
  );
 
}

export default CreateClaim;