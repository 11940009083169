
import typography from "../../../../assets/theme/base/typography";

import colors from "../../../../assets/theme/base/colors";



const { size, fontWeightMedium } = typography;


const tab = {
  styleOverrides: {
    root: {
     
      fontSize: size.sm,
      fontWeight: fontWeightMedium,
      color : colors.veriskBlack.main ,     
      textTransform: 'none',
    },
    '&.Mui-selected': {
      color: colors.veriskBlack.main,
    
    },
  },
 
};

export default tab;
