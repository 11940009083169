import pxToRem from "../../../assets/theme/functions/pxToRem"

export default {
    base: {
      veriskBlue : '#00358E',
      tabsBgColor : '#002d61'
    },
    titletextStyles : {
      textColor :'white',
      fontWeight : 500,
      fontSizeClaimDashBoardView : pxToRem(15),
      fontSize : pxToRem(25),
      titlePaddingLeft : pxToRem(10),
      titleMarginLeft: '0px',
      variantType : 'caption',
      componentType : 'h1',
      fontWeightClaimTotal : "medium",
      fontSizeClaimTotal : pxToRem(11)
    },
    tabTextStyles:
    {
      fontWeight : 'large',
      fontSize : pxToRem(15),
      marginLeft : 2,
      
    },

    navigationBarAllignment :
    { 
      margin : '0px',
      height :'100%',
      sidebarWidth : pxToRem(250)
    },
    navigationBarShape :
    { 
      borderRadius : '0px'
    },
    navBarTitleAllignment :
    { 
      paddingTop : 4,
      paddingTopClaimView: 2,
      paddingBottom : 4 ,
      paddingBottomClaimView : 2,
      paddingXaxis : 2,
      textAlign : 'center',
      marginTop : 1
    },
    activeTabStyle :
    { 
    'backgroundColor': '#0066ff', 
    'height': pxToRem(46),
    'width':'10px' 
  },
  tabStyles:
  {
    displayStyle : { xs: "block", xl: "none" },
    position : 'absolute',
    marginTop : 0,
    marginRight : 0,
    padding : 1.625,
    cursorType : 'pointer'
  },
  tabLinkStyles:
  {
    display : "flex",
    alignItems : "center",
    textDecoration : 'none' 
  },
  skeletonStyles :
  { 
    width : pxToRem(210), 
    height :pxToRem(70)   
    
  }
  }
  export const svgIconStyles = { 
    color : 'white',
    viewBox : "0 7 40 40", 
    fontSize : "small"
}