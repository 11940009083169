
export const superUserValidateRequiredFields = (requiredFields,errorFields,requestBody)=>
{
    const areAllRequiredFieldsPresent = requiredFields.every(
        (field) =>
            requestBody[field] !== undefined &&
        requestBody[field] !== ''
      );
      
      const areAllValidValues = errorFields.every(
        (field) =>
            requestBody[field] !== undefined &&
        requestBody[field] !== true
      );
    return areAllRequiredFieldsPresent && areAllValidValues
}