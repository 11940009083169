export var Status;
(function (Status) {
    Status[Status["Active"] = 0] = "Active";
    Status[Status["Locked"] = 1] = "Locked";
})(Status || (Status = {}));
export function getStatusLabel(status) {
    switch (status) {
        case Status.Active:
            return "Active";
        case Status.Locked:
            return "Locked";
        default:
            return "Unknown";
    }
}
