import pxToRem from "../../../../assets/theme/functions/pxToRem";
export const uploadCardStyles = {
    boxShadow: `0rem 0.25rem 0.75rem -0.125rem rgba(0, 0, 0, 0.1)`,
    border: '2px solid rgba(0, 0, 0, 0.06)',
    borderRadius: 1.4,
    height: 'auto',
    width: "100%",
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', 
    position: 'relative', 
    mt:2
  };

  export const cardTitleStyles = {
    fontSize : pxToRem(17) ,
    fontWeight : "medium",  
    color :"veriskBlack",
    textTransform :"capitalize",
   
}