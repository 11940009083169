import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox"
import { drawerContainer } from "../common-styles/styles"
import { useMaterialUIController } from "../../../context";
import { handleDebounce, phoneFields } from "../ClaimUtils";
import MDTypography from "../../../components/MDTypography";
import { useEffect, useState } from "react";
import { PhoneTextField } from "./components/PhoneTextField";
import pxToRem from "../../../assets/theme/functions/pxToRem";



const ContactInfo = () =>
{
    const [controller,dispatch] = useMaterialUIController();
    const { claimRequestBody } = controller

    const [phones, setPhones] = useState({
        "Home Phone": { type: 0, number: claimRequestBody['Home Phone']?.number || '', extension: claimRequestBody['Home Phone']?.extension || '44', error: false },
        "Mobile Phone": { type: 1, number: claimRequestBody['Mobile Phone']?.number || '', extension: claimRequestBody['Mobile Phone']?.extension || '44', error: false },
        "Business Phone": { type: 2, number: claimRequestBody['Business Phone']?.number|| '', extension: claimRequestBody['Business Phone']?.extension || '44', error: false }
    });
    const phonePattern = /^\d*$/;
    const [missingNumberMessage, setMissingNumberMessage] = useState(''); 
  
    
    useEffect(() => {
        
        const hasAtLeastOneNumber = Object.values(phones).some((phone) => phone.number.trim() !== '');
        if (!hasAtLeastOneNumber) {
            setMissingNumberMessage('Please enter at least one phone number.');
        }
    
    }, []);

   const handlePhoneNumberChange = (event,field) => 
  { 
    const { value } = event.target;

    const isValid = phonePattern.test(value) && value.length >= 7 && value.length <= 15;
    const hasValue = value.length !== 0;
    const currentValues = claimRequestBody[field] || {};
    setPhones((prevPhones) => ({
        ...prevPhones,
        [field]: {
            ...prevPhones[field],
            number: value,
            error: hasValue ? !isValid : false 
        }
        }));
    
    const hasAtLeastOneNumber = Object.values({
            ...phones,
            [field]: { ...phones[field], number: value }
        }).some((phone) => phone.number.trim() !== '');

    if (!hasAtLeastOneNumber) {
        setMissingNumberMessage('Please enter at least one phone number.');
    } else {
        setMissingNumberMessage(''); 
    }
    if (!isValid && hasValue) {
        handleDebounce(field, {...currentValues, phoneError: true, number: value,type: phones[field]?.type }, dispatch);
    
    } else {
        handleDebounce(field, {...currentValues, phoneError: false, number: value,type: phones[field]?.type }, dispatch);
    }
};

const handleEmailChange = (event) => 
{
    const { value } = event.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(value)
    const emailError = value && !isValid
    handleDebounce('email', { emailError: emailError, email: value }, dispatch);

    };

const handleCountryCodeChange = (event,field) => 
{

    const currentValues = claimRequestBody[field] || {};
    handleDebounce(field, {...currentValues,
        extension:  event?.target?.value},dispatch);
    
};

    return( 
    <MDBox  sx={drawerContainer}>
          
        <MDBox mx={2} >
        <MDTypography  variant="gradient" color={"veriskRed"}  sx={{ display: 'block',fontFamily : 'Roboto' }}>
                {missingNumberMessage}
            </MDTypography>
            <FormControl fullWidth variant="standard">
                {phoneFields.map((field) => (
                    <PhoneTextField
                        key={field} 
                        textFieldLabel={field}  
                        phoneError={phones[field]?.error || claimRequestBody[field]?.phoneError}  
                        handleCountryCodeChange={(e) => handleCountryCodeChange(e, field)} 
                        handlePhoneNumberChange={(e) => handlePhoneNumberChange(e, field)} 
                        countryCode={claimRequestBody[field]?.extension}
                        phoneNumberValue={claimRequestBody[field]?.number}
                        
                    />
                ))}

                <TextField
                  fullWidth
                  margin="normal"
                  id="email"
                  error = {claimRequestBody['email']?.emailError}
                  helperText = {claimRequestBody['email']?.emailError && "Please enter a valid Email"}
                  value={claimRequestBody['email']?.email}
                  label="Email Address"
                  required //need to change this in future
                  variant="standard"
                  type="email"
                  onChange = {handleEmailChange}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                />
             
            </FormControl>
        </MDBox>
    </MDBox>)
}

export default ContactInfo;


