import { FormControl, InputLabel, Select } from "@mui/material"
import MDBox from "../../../../components/MDBox"
import MenuItem from '@mui/material/MenuItem';
import PropTypes from "prop-types"

const DropDownField = ({dropDownLabel,dropDownList,selectedValue,handleValueChange}) =>
{
  return(
        <MDBox mx={2} my={2}>
        <FormControl fullWidth variant="standard">
            <InputLabel id={dropDownLabel+"select-label"}>{dropDownLabel}</InputLabel>
            <Select
                
                id={dropDownLabel+"-select"}
                value = {selectedValue}
                onChange={handleValueChange}
                label={dropDownLabel}
            >
                
                {dropDownList.map((item, index) => {

                return (
                <MenuItem key={index} value={item?.value}>
                    {item?.name}
                </MenuItem>
                );
                })}
            </Select>
        </FormControl>
    </MDBox>
  )
}

export default DropDownField

DropDownField.propTypes = {
    dropDownLabel : PropTypes.string,
    handleValueChange: PropTypes.func.isRequired,                   
    selectedValue: PropTypes.string, 
    dropDownList: PropTypes.array           
};

DropDownField.defaultProps = {
    dropDownLabel : '',
    selectedValue: '',   
    dropDownList : []

};
