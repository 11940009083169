export const CxURL = "https://callisto.claimxperience.co.uk/projects/"

export const countryCodes = [
    {"code": "+44", "country": "United Kingdom"},
    {"code": "+61", "country": "Australia"},
    {"code": "+32", "country": "Belgium"},
    {"code": "+1", "country": "Canada"},
    {"code": "+33", "country": "France"},
    {"code": "+49", "country": "Germany"},
    {"code": "+91", "country": "India"},
    {"code": "+353", "country": "Ireland"},
    {"code": "+352", "country": "Luxembourg"},
    {"code": "+31", "country": "Netherlands"},
    {"code": "+64", "country": "New Zealand"},
    {"code": "+1", "country": "United States of America"}

]