import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGE, FAILED, IDLE, LOADING, SUCCEEDED } from "../../models/constants";
import { superUserGetSuperUsers } from "../../actions/super-user/super-user-actions";
var initialState = {
    loadingSuperUsers: IDLE,
    superUsers: [],
    totalSuperUsers: 0,
    pageNumber: DEFAULT_PAGE
};
export var superUserSlice = createSlice({
    name: "superUsers",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(superUserGetSuperUsers === null || superUserGetSuperUsers === void 0 ? void 0 : superUserGetSuperUsers.pending, function (state) {
            state.loadingSuperUsers = LOADING;
        });
        builder.addCase(superUserGetSuperUsers === null || superUserGetSuperUsers === void 0 ? void 0 : superUserGetSuperUsers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.superUsers = payload.data;
            state.totalSuperUsers = payload.total;
            state.loadingSuperUsers = SUCCEEDED;
        });
        builder.addCase(superUserGetSuperUsers === null || superUserGetSuperUsers === void 0 ? void 0 : superUserGetSuperUsers.rejected, function (state) {
            state.loadingSuperUsers = FAILED;
        });
    },
});
export default superUserSlice.reducer;
export var getSuperUsers = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.superUserState) === null || _a === void 0 ? void 0 : _a.superUsers; };
export var getLoadingSuperUsersStatus = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.superUserState) === null || _a === void 0 ? void 0 : _a.loadingSuperUsers; };
export var getSuperUsersCount = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.superUserState) === null || _a === void 0 ? void 0 : _a.totalSuperUsers; };
