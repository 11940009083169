
import typography from "../../../../assets/theme/base/typography";
import colors from "../../../../assets/theme/base/colors";

import pxToRem from "../../../../assets/theme/functions/pxToRem";
import rgba from "../../../../assets/theme/functions/rgba";

const { size, fontWeightRegular } = typography;
const { white } = colors;

const stepLabel = {
  
};

export default stepLabel;
