var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { EndPoints } from "../models/constants/endpoints";
import { apiClient } from "./configure-services";
export var getAssignmentsAsync = function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                requestUrl = "".concat(EndPoints.assignments, "?ClaimNumber=").concat(request === null || request === void 0 ? void 0 : request.claimNumber, "&pageNumber=").concat(request.pageNumber, "&pageSize=").concat(request.pageSize, "&paginate=").concat(request.paginate);
                return [4 /*yield*/, apiClient.get(requestUrl)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
        }
    });
}); };
export var createAssignmentAsync = function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "".concat(EndPoints.assignments);
                return [4 /*yield*/, apiClient.post(requestUrl, request)];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 201) {
                    throw new Error('Failed to create assignment');
                }
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_1 = _a.sent();
                throw new Error('Failed to create assignment');
            case 3: return [2 /*return*/];
        }
    });
}); };
export var statusUpdateAsync = function (assignmentID, statusDetails) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, body, headers, response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "assignments/" + assignmentID + "/status";
                body = statusDetails;
                headers = {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                };
                return [4 /*yield*/, apiClient.patch(requestUrl, body, {
                        headers: headers
                    })];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 200 && (response === null || response === void 0 ? void 0 : response.status) !== 201) {
                    throw new Error('Failed to update status');
                }
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_2 = _a.sent();
                throw new Error('Failed to update status');
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getAssignmentStatusAsync = function (assignmentID) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "assignments/" + assignmentID + "/status";
                return [4 /*yield*/, apiClient.get(requestUrl)];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                    throw new Error('Failed to get status');
                }
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_3 = _a.sent();
                throw new Error('Failed to get status');
            case 3: return [2 /*return*/];
        }
    });
}); };
export var createReferralAssignmentAsync = function (referralAssignmentDetails) { return __awaiter(void 0, void 0, void 0, function () {
    var assignmentID, requestUrl, assignmentId, request, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                assignmentID = referralAssignmentDetails === null || referralAssignmentDetails === void 0 ? void 0 : referralAssignmentDetails.assignmentId;
                requestUrl = "assignments/" + assignmentID + "/refer";
                assignmentId = referralAssignmentDetails.assignmentId, request = __rest(referralAssignmentDetails, ["assignmentId"]);
                return [4 /*yield*/, apiClient.post(requestUrl, request)];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 200) {
                    throw new Error('Failed to create referral assignment');
                }
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_4 = _a.sent();
                throw new Error('Failed to create referral assignment');
            case 3: return [2 /*return*/];
        }
    });
}); };
export var postAssignAssignmnetAsync = function (assignmentID, recipientsXm8UserId) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUrl, body, headers, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                requestUrl = "assignments/" + assignmentID + "/assign";
                body = {
                    recipientsXm8UserId: recipientsXm8UserId
                };
                headers = {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                };
                return [4 /*yield*/, apiClient.post(requestUrl, body, {
                        headers: headers
                    })];
            case 1:
                response = _a.sent();
                if ((response === null || response === void 0 ? void 0 : response.status) !== 200 && (response === null || response === void 0 ? void 0 : response.status) !== 201) {
                    throw new Error('Failed to assign assignment');
                }
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            case 2:
                error_5 = _a.sent();
                throw new Error('Failed to assign assignment');
            case 3: return [2 /*return*/];
        }
    });
}); };
