//Assignment Statuses
export var Created = 0;
export var Contacted = 1;
export var Inspected = 2;
export var JobStarted = 3;
export var JobCompleted = 4;
export var Failed = 5;
export var InspectionScheduled = 6;
export var AssignmentStatuses = [
    { value: Created, name: 'Created' },
    { value: Contacted, name: 'Contacted' },
    { value: Inspected, name: 'Inspected' },
    { value: JobStarted, name: 'Job Started' },
    { value: JobCompleted, name: 'Job Completed' },
    { value: Failed, name: 'Failed' },
    { value: InspectionScheduled, name: 'Inspection Scheduled' },
];
//Job Types
export var General = 0;
export var Estimation = 1;
export var Validation = 2;
export var JobTypes = [
    { value: General, name: 'General' },
    { value: Estimation, name: 'Estimation' },
    { value: Validation, name: 'Validation' },
];
//need to change this in future
export var assigneeList = [
    { value: 'broge', name: 'Ben Rogers' },
];
export var DataSets = [
    { value: 1, name: 'Callisto - Desk' },
    { value: 2, name: 'Callisto - Loss Adjuster' },
    { value: 3, name: 'Callisto - Repair Contractor' },
];
export var JobSize = [
    { value: 1, name: 'Small...up to £3,500.00' },
    { value: 2, name: 'Mod....£3,501.00-£10,000.00' },
    { value: 3, name: 'Med....£10,001.00-£20,000.00' },
    { value: 4, name: 'Lrg....£20,001.00-£50,000.00' },
    { value: 5, name: 'VLrg....£50,001.00-£100,000.00' },
    { value: 6, name: 'XLrg....£100,001.00 and up' },
];
export var AssignmentsData = [
    {
        dataSet: DataSets[0],
        status: AssignmentStatuses[6].name, // Using the name property
        estimator: 'John Smith',
        date: (function () {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 15);
            return currentDate.toISOString();
        })()
    },
    {
        dataSet: DataSets[1],
        status: AssignmentStatuses[3].name, // Using the name property
        estimator: null,
        date: (function () {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 21);
            return currentDate.toISOString();
        })()
    }
];
export var assignmentRequest = {
    pageNumber: 1,
    pageSize: 100,
    paginate: false,
    claimNumber: ""
};
