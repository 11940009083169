import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import pxToRem from '../../../../assets/theme/functions/pxToRem';
import colors from '../../../../assets/theme/base/colors';



export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
 
  minWidth: pxToRem(80),
  padding: `${pxToRem(3)} ${pxToRem(0)}`,
  borderRadius: 0,
  fontSize: pxToRem(10), 
  color: colors?.veriskBlack?.main, 
  fontWeight : 400,
  alignItems: "left",
  transition: "background-color 300ms ease, color 300ms ease",
  "&:hover": {
    backgroundColor: theme.palette.action.hover, 
    color: theme.palette.text.secondary, 
  },
  "&.Mui-selected": {
    backgroundColor: colors?.veriskClaimViewIcon?.main, 
    color: colors?.veriskWhite?.main, 
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.action.selectedHover,
    color: theme.palette.text.secondary, 
  },
}));


export const descriptionStyles = {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      fontSize : pxToRem(15),
      mt : 6,
      ml: 4,
      mr: 4,
      fontWeight : "regular"
}


export const iconStyles = {
   marginRight: 2, 
   color: colors?.veriskBlack?.main
}


export const iconBoxStyles = {
  display: 'flex', 
  alignItems: 'center'
}

export const countryCodeLabelStyles = {
  fontSize: pxToRem(13),
  color: "#707070",
  fontWeight : "regular"
  }

export const formLabelStyles = {
  position: 'absolute',
  mt:3,
  fontSize: pxToRem(17),
  lineHeight: pxToRem(20),
  letterSpacing: '0px',
  opacity: 1,
  '&.Mui-focused': {
    color: 'veriskBlack',
  },
}

