
import PropTypes from 'prop-types';
export function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <h2>Oops! Something went wrong.</h2>
        <button onClick={resetErrorBoundary}>Reload page</button>
      </div>
    );
  }
  ErrorFallback.propTypes = {
    error: PropTypes.object.isRequired,
    resetErrorBoundary: PropTypes.func.isRequired
  };
    