//PerilType
export var EOW = 0;
export var Explosion = 1;
export var Fire = 2;
export var Flood = 3;
export var Heave = 4;
export var Impact = 5;
export var Landslip = 6;
export var Lightning = 7;
export var MalDamage = 8;
export var Storm = 9;
export var Subsidence = 10;
export var Theft = 11;
export var Tornado = 12;
export var PerilTypes = [
    { value: EOW, name: 'Escape of Water' },
    { value: Explosion, name: 'Explosion' },
    { value: Fire, name: 'Fire' },
    { value: Flood, name: 'Flood' },
    { value: Heave, name: 'Heave' },
    { value: Impact, name: 'Impact' },
    { value: Landslip, name: 'Landslip' },
    { value: Lightning, name: 'Lightning' },
    { value: MalDamage, name: 'MalDamage' },
    { value: Storm, name: 'Storm' },
    { value: Subsidence, name: 'Subsidence' },
    { value: Theft, name: 'Theft' },
    { value: Tornado, name: 'Tornado' }
];
//Cause
export var Bath = 0;
export var BurstPipe = 1;
export var Other = 2;
export var Causes = [
    { value: Bath, name: 'Bath' },
    { value: BurstPipe, name: 'Burst Pipe' },
    { value: Other, name: 'Other' },
];
//Special Attention
export var Complaint = 0;
export var Vulnerable = 1;
export var SpecialAttentions = [
    { value: Complaint, name: 'Complaint' },
    { value: Vulnerable, name: 'Vulnerable Customers' },
];
//Claim Statuses
export var Pending = 0;
export var ClaimStatuses = [
    { value: Pending, name: 'Pending' },
];
//Claim Types
export var Client = 0;
export var ClaimTypes = [
    { value: Client, name: 'Client' },
];
//Coverage Types
export var Structure = 0;
export var Contents = 1;
export var CoverageTypes = [
    { value: Structure, name: 'Structure' },
    { value: Contents, name: 'Contents' },
];
//Phone Types
export var Home = 0;
export var Business = 1;
export var Cell = 2;
export var OtherPhone = 3;
export var PhoneTypes = [
    { value: Home, name: 'Home' },
    { value: Business, name: 'Business' },
    { value: Cell, name: 'Cell' },
    { value: OtherPhone, name: 'Other' },
];
//Address Types
export var Property = 2;
export var AddressTypes = [
    { value: Home, name: 'Home' },
    { value: Business, name: 'Business' },
    { value: Property, name: 'Property' },
];
