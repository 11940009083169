var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { claimCreateClaim, claimGetClaim, claimGetClaims, getClaimNotes } from "../actions/claims-actions";
import { DEFAULT_PAGE, FAILED, IDLE, LOADING, SUCCEEDED } from "../models/constants/index";
var initialState = {
    loadingClaims: IDLE,
    loadingClaim: IDLE,
    loadingCreateClaim: IDLE,
    loadingNotes: IDLE,
    claims: [],
    notes: [],
    filteredNotes: [],
    totalNotes: 0,
    claimDashboardView: false,
    selectedClaim: undefined,
    pageNumber: DEFAULT_PAGE,
    totalClaims: 0,
    isClaimSelected: false,
    isFilterApplied: false,
    selectedAssignmentList: [],
    selectedNotesAddedByList: []
};
export var claimsSlice = createSlice({
    name: "claims",
    initialState: initialState,
    reducers: {
        handleSelectedClaim: function (state, _a) {
            var payload = _a.payload;
            state.selectedClaim = payload;
        },
        setIsClaimSelected: function (state, _a) {
            var payload = _a.payload;
            state.isClaimSelected = payload;
        },
        setLoadingClaim: function (state, _a) {
            var payload = _a.payload;
            state.loadingClaim = payload;
        },
        setLoadingNotes: function (state, _a) {
            var payload = _a.payload;
            state.loadingNotes = payload;
        },
        handleFilteredNotes: function (state, _a) {
            var payload = _a.payload;
            state.filteredNotes = payload;
        },
        handleIsFilterApplied: function (state, _a) {
            var payload = _a.payload;
            state.isFilterApplied = payload;
        },
        handleSelectedList: function (state, _a) {
            var payload = _a.payload;
            state.selectedAssignmentList = payload.selectedAssignmentList;
            state.selectedNotesAddedByList = payload.selectedNotesAddedByList;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(claimGetClaims.pending, function (state) {
            state.loadingClaims = LOADING;
        });
        builder.addCase(claimGetClaims.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.claims = payload.data;
            state.totalClaims = payload.total;
            state.loadingClaims = SUCCEEDED;
        });
        builder.addCase(claimGetClaims.rejected, function (state) {
            state.loadingClaims = FAILED;
        });
        builder.addCase(claimCreateClaim.pending, function (state) {
            state.loadingCreateClaim = LOADING;
        });
        builder.addCase(claimCreateClaim.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.claims = __spreadArray([payload.data], state.claims, true);
            state.loadingCreateClaim = SUCCEEDED;
        });
        builder.addCase(claimCreateClaim.rejected, function (state) {
            state.loadingCreateClaim = FAILED;
        });
        builder.addCase(claimGetClaim.pending, function (state) {
            state.loadingClaim = LOADING;
        });
        builder.addCase(claimGetClaim.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.selectedClaim = payload.data;
            state.loadingClaim = SUCCEEDED;
        });
        builder.addCase(claimGetClaim.rejected, function (state) {
            state.loadingClaim = FAILED;
        });
        // Handle getClaimNotes thunk
        builder.addCase(getClaimNotes.pending, function (state) {
            state.loadingNotes = LOADING;
        });
        builder.addCase(getClaimNotes.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.notes = payload.data;
            state.totalNotes = payload.total;
            state.loadingNotes = SUCCEEDED;
        });
        builder.addCase(getClaimNotes.rejected, function (state) {
            state.loadingNotes = FAILED;
        });
    },
});
export var handleSelectedClaim = (_a = claimsSlice.actions, _a.handleSelectedClaim), setIsClaimSelected = _a.setIsClaimSelected, setLoadingClaim = _a.setLoadingClaim, setLoadingNotes = _a.setLoadingNotes, handleFilteredNotes = _a.handleFilteredNotes, handleIsFilterApplied = _a.handleIsFilterApplied, handleSelectedList = _a.handleSelectedList;
export default claimsSlice.reducer;
export var getClaims = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.claims; };
export var getSelectedClaim = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.selectedClaim; };
export var getClaimDashboardView = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.claimDashboardView; };
export var getClaim = function (state, claimId) {
    return state.claimsState.claims.find(function (claim) { return claim.claimId == claimId; });
};
export var loadingClaimsStatus = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.loadingClaims; };
export var loadingClaimStatus = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.loadingClaim; };
export var getClaimsCount = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.totalClaims; };
export var getNotesCount = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.totalNotes; };
export var getIsClaimSelected = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.isClaimSelected; };
export var getNotes = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.notes; };
export var loadingNotesStatus = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.loadingNotes; };
export var getFilteredNotes = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.filteredNotes; };
export var getFilteredStatus = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.isFilterApplied; };
export var getSelectedAssignmentList = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.selectedAssignmentList; };
export var getSelectedNotesList = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.claimsState) === null || _a === void 0 ? void 0 : _a.selectedNotesAddedByList; };
