
import colors from "../../../assets/theme/base/colors";


import rgba from "../../../assets/theme/functions/rgba";
import pxToRem from "../../../assets/theme/functions/pxToRem";


const { veriskDividerColor, transparent, white } = colors;

const divider = {
  styleOverrides: {
    root: {
     
      
    
    
      
      "&.MuiDivider-root": {
       
  
        border: `1px solid ${veriskDividerColor?.main}`,
        opacity: 1,
        height: pxToRem(0)
      },
    },

    vertical: {
      backgroundColor: "#E4E4E4",
      backgroundImage: `linear-gradient(to bottom, ${rgba(veriskDividerColor.main, 0)}, ${rgba(
        veriskDividerColor.main,
        0.4
      )}, ${rgba(veriskDividerColor.main, 0)}) `,
      width: pxToRem(2),
      height: "100%",
      margin: `0 ${pxToRem(16)}`,
      borderRight: "none",
    },

    light: {
      backgroundColor: "#E4E4E4",
      backgroundImage: `linear-gradient(to right, ${rgba(white.main, 0)}, ${white.main}, ${rgba(
        white.main,
        0
      )}) !important`,

      "&.MuiDivider-vertical": {
        backgroundImage: `linear-gradient(to bottom, ${rgba(white.main, 0)}, ${white.main}, ${rgba(
          white.main,
          0
        )}) !important`,
      },
    },
  },
};

export default divider;
